import React, { Component } from "react";
import Slider from "react-slick";
import BizClass from "../Biz_Home.module.scss";
import OfferOne from "../../../images/Offer-1.jpeg";
import OfferTwo from "../../../images/Offer-02.jpeg";
import OfferThree from "../../../images/Offer-03.jpeg";
import OfferFour from "../../../images/Offer-04.jpeg";
import OfferFive from "../../../images/Offer-05.jpeg";

export default class RewardsOffers extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: "true",
      centerMode: true,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    };
    return (
      <Slider {...settings} className={BizClass.Slider}>
        {sliderData.map((data, index) => {
          return (
            <>
              <div className={BizClass.Card_Div}>
                <div>{data.Img}</div>
              </div>
            </>
          );
        })}
      </Slider>
    );
  }
}

const sliderData = [
  {
    Img: (
      <img
        decoding="async"
        className="swiper-slide-image"
        src={OfferOne}
        alt="Five-Knots"
      />
    ),
  },
  {
    Img: <img src={OfferTwo} alt="" className="swiper-slide-image" />,
  },
  {
    Img: <img src={OfferThree} alt="" />,
  },
  {
    Img: <img src={OfferFour} alt="" />,
  },
  {
    Img: <img src={OfferFive} alt="" />,
  },
];
