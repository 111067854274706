import React from "react";
import BizClass from "../Biz_Home.module.scss";

const Video = () => {
  return (
    <div className={BizClass.Shere_Video}>
      <video width="100%" height="500" muted controls>
        <source
          src="https://sherbazaar-vega-assetroot.s3.ap-south-1.amazonaws.com/uploads/public/cdn/HowPlayVideo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
