import React, { useEffect, useState } from "react";
import BizClass from "./Header.module.scss";
import { VscThreeBars } from "react-icons/vsc";
import { ImCross } from "react-icons/im";
import { Link, useParams } from "react-router-dom";
const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const { slug } = useParams();
  useEffect(() => {
    console.log(slug);
  }, []);

  const openMenuBar = () => {
    setMenu(!menu);
  };

  const [activeMenu, setActiveMenu] = useState("1");

  return (
    <div className={BizClass.Header}>
      <div className={BizClass.Client_Logo}>
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/06/Logo.png"
          alt=""
        />
      </div>
      <div className={BizClass.MenuListing}>
        <div className={BizClass.Lsit}>
          <ul>
            <div>
              <div
                className={
                  activeMenu === "1" ? BizClass.ActiveLink : BizClass.link_Div
                }
              ></div>
              <Link
                className={BizClass.Menu}
                to="/"
                onClick={() => setActiveMenu("1")}
              >
                Home
              </Link>
            </div>
            <div>
              <div
                className={
                  activeMenu === "2" ? BizClass.ActiveLink : BizClass.link_Div
                }
              ></div>
              <Link
                className={BizClass.Menu}
                to="/about-us"
                onClick={() => setActiveMenu("2")}
              >
                About Us
              </Link>
            </div>
            <div>
              <div
                className={
                  activeMenu === "3" ? BizClass.ActiveLink : BizClass.link_Div
                }
              ></div>
              <Link
                className={BizClass.Menu}
                to="/how-to-play"
                onClick={() => setActiveMenu("3")}
              >
                How To Play
              </Link>
            </div>
            <div>
              <div
                className={
                  activeMenu === "5" ? BizClass.ActiveLink : BizClass.link_Div
                }
              ></div>
              <Link
                className={BizClass.Menu}
                to="/contact-us"
                onClick={() => setActiveMenu("5")}
              >
                Contact
              </Link>
            </div>
            {/* <div>
              <div
                className={
                  activeMenu === "6" ? BizClass.ActiveLink : BizClass.link_Div
                }
              ></div>
              <Link
                className={BizClass.Menu}
                to="/blog"
                onClick={() => setActiveMenu("6")}
                target="_blank"
              >
                Blog
              </Link>
            </div> */}
          </ul>
        </div>
      </div>

      <div className={BizClass.MenuBar}>
        <VscThreeBars onClick={openMenuBar} />
        {menu ? (
          <div className={BizClass.Container}>
            <div className={BizClass.Section_Bar}>
              <ImCross onClick={openMenuBar} />
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/06/Logo.png"
                alt=""
              />
              <div className={BizClass.MenuConatiner}>
                <Link className={BizClass.Menu} to="/">
                  Home
                </Link>
                <Link className={BizClass.Menu} to="/about-us">
                  About Us
                </Link>
                <Link className={BizClass.Menu} to="/how-to-play">
                  How To Play
                </Link>
                <Link className={BizClass.Menu} to="/contact-us">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
