import React, { Component } from "react";
import Slider from "react-slick";
import BizClass from "../Biz_Home.module.scss";
import Wonder from "../../../images/Wonder.jpeg";
import Satta from "../../../images/SattePeSatta.jpeg";
import BigBoss from "../../../images/BigBoss.jpeg";
import FastFurious from "../../../images/FastFurious.jpeg";
import OldIsGold from "../../../images/OldIsGold.jpeg";
import NewAgeTech from "../../../images/NewAgeTech.jpeg";
import Cracking from "../../../images/Crypto.jpeg";
import Strangers from "../../../images/Strangers.jpeg";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: "true",
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    };
    return (
      <Slider {...settings} className={BizClass.Slider}>
        {sliderData.map((data, index) => {
          return (
            <>
              <div className={BizClass.Card_Div}>
                <div>{data.Img}</div>
              </div>
            </>
          );
        })}
      </Slider>
    );
  }
}

const sliderData = [
  {
    Img: (
      <img
        decoding="async"
        className="swiper-slide-image"
        src={Wonder}
        alt="Five-Knots"
      />
    ),
  },
  {
    Img: <img src={Satta} alt="" className="swiper-slide-image" />,
  },
  {
    Img: <img src={BigBoss} alt="" />,
  },
  {
    Img: <img src={FastFurious} alt="" />,
  },
  {
    Img: <img src={OldIsGold} alt="" />,
  },
  {
    Img: <img src={NewAgeTech} alt="" />,
  },
  {
    Img: <img src={Cracking} alt="" />,
  },
  {
    Img: <img src={Strangers} alt="" />,
  },
  // {
  //   Img: (
  //     <img
  //       decoding="async"
  //       className="swiper-slide-image"
  //       src="https://sherbazaar.co/wp-content/uploads/elementor/thumbs/Rocket-Others-ptz1ujryswr8il4qzggiu1bfh9bif93lzy8sk2evwc.png"
  //       alt="Rocket-Others"
  //     ></img>
  //   ),
  // },
  // {
  //   Img: (
  //     <img
  //       decoding="async"
  //       className="swiper-slide-image"
  //       src="https://sherbazaar.co/wp-content/uploads/elementor/thumbs/Witty-Wizard-ptz1ujryswr8il4qzggiu1bfh9bif93lzy8sk2evwc.png"
  //       alt="Witty-Wizard"
  //     ></img>
  //   ),
  // },
];
