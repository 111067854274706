import React from "react";
import BizClass from "../Biz_Home.module.scss";
import GlimpseFive from "../../../images/Glimpse-05.JPEG";

const DownloadApp = () => {
  return (
    <div className={BizClass.Content_Section}>
      <div className={BizClass.Content}>
        <h1>Download App</h1>
        <span>
          <a href="https://sherbazaarcdn.com/sherbazaar.apk" target="_blank">
            <img
              src="https://sherbazaar.co/wp-content/uploads/2022/06/google-play.svg
"
              alt=""
            />
          </a>

          <a href="https://apps.apple.com/in/app/sherbazaar/id1613349921" target="_blank">
            <img
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/appstore-2.svg"
              alt=""
            />
          </a>
        </span>

        <p>OR</p>
        <span>To Redeem Sher Coins Download Sherbazaar Pro App</span>
        <a download href="https://sherbazaarcdn.com/sherbazaar.apk" className={BizClass.AppLink}>Download Now</a>
      </div>

      <img src={GlimpseFive} alt="" className={BizClass.DownLoadImg} />
    </div>
  );
};

export default DownloadApp;
