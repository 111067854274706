import React, { useState, useEffect } from "react";
import BizClass from "./Biz_Home.module.scss";
import Android from "../../images/Android";
import Video from "./Video/Video";
import { BsUniversalAccessCircle } from "react-icons/bs";
import { BiSquare, BiTrophy } from "react-icons/bi";
import {
  FaFacebookF,
  FaInstagram,
  FaStar,
  FaMobileAlt,
  FaTwitter,
  FaApple,
  FaBehance,
  FaQuoteRight,
} from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { RiProfileLine } from "react-icons/ri";
import { TiPlus } from "react-icons/ti";
import { MdArrowForwardIos, MdArrowBackIosNew, MdNotificationsActive } from "react-icons/md";
import Footer from "./Footer/Footer";
import HowPlay from "./HowPlay/HowPlay";
import DownloadApp from "./DownloadApp_Section/DownloadApp";
import Responsive from "./Slider/SliderOne";
import ImageOne from "../../images/image-01.png";
import MCPeople from "../../images/MCPeople.jpeg";
import Abhinay from "../../images/Abhinay.jpeg";
import Vikash from "../../images/Vikash.jpeg";
import Faizan from "../../images/Faizan.jpeg";
import Divya from "../../images/Divya.jpeg";
import Sudin from "../../images/Sudin.jpeg";
import Shubham from "../../images/Shubham.jpeg";
import Rhutuja from "../../images/Rhutuja.jpeg";
import GlimpseOne from "../../images/Glimpse-01.JPEG";
import GlimpseTwo from "../../images/Glimpse-02.JPEG";
import GlimpseThree from "../../images/Glimpse-03.JPEG";
import GlimpseFour from "../../images/Glimpse-04.JPEG";
import GlimpseFive from "../../images/Glimpse-05.JPEG";
import GlimpseSix from "../../images/Glimpse-06.JPEG";
import GlimpseSeven from "../../images/Glimpse-07.JPEG";
import GlimpseEight from "../../images/Glimpse-08.JPEG";
import GlimpseNine from "../../images/Glimpse-09.JPEG";
import GlimpseTen from "../../images/Glimpse-10.JPEG";
import GlimpseEleven from "../../images/Glimpse-11.JPEG";
import GlimpseTwelve from "../../images/Glimpse12.JPEG";
import GlimpseThirteen from "../../images/Glimpse-13.JPEG";
import GlimpseFourteen from "../../images/Glimpse14.JPEG";
import GlimpseFifthteen from "../../images/Glimpse-15.JPEG";
import RewardsOffers from "./Rewards&Offers/RewardsOffers";
import Sachin from "../../images/SachinYadav(Designer).png.jpg";
import VikashKumar from "../../images/VikashKumar.jpeg";
import Rachit from "../../images/Rachit.jpeg";
import AOS from "aos";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { publicIpv4 } from "public-ip";

const Home = () => {
  const BaseUrl = "https://api.pro.sherbazaar.com/api/v2/admin/";
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    AOS.init(3000);
    AOS.refresh(3000);
  }, []);

  const _subscribeNow = async (e) => {
    if (e) e.preventDefault();
    let ip = "0.00.00.0";
    if (!email) {
      toast.warn("Please Enter email");
      return;
    } else if (email) {
      let regEx = new RegExp(
        "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$",
        "i"
      );
      if (!regEx.test(email)) {
        toast.warn("Please Enter Valid Email");
        return;
      }
    }
    setIsLoading(true);
    try {
      ip = await publicIpv4();
    } catch (error) { }

    try {
      let response = await axios.post(`${BaseUrl}support/subscribe`, {
        email: email,
        ip: ip ? ip.toString() : "",
      });
      setIsLoading(false);

      if (response && response.status === 200) {
        if (response && response.data && response.data.code === 200) {
          toast.success("Subscribe successfully");

          setEmail("");
        } else {
          toast.error(
            response.data && response.data.message
              ? response.data.message
              : "Something want to wrong .! Please try again"
          );
        }
      } else {
        toast.error(
          response && response.data && response.data.message
            ? response.data.message
            : "Something want to wrong .! Please try again"
        );
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : "Something want to wrong .! Please try again"
        );
        return;
      }
      toast.error(
        error && error.message
          ? error.message
          : "Something want to wrong .! Please try again"
      );
    }
  };

  function GrNextbtn(props) {
    const { className, style, onClick } = props;

    return (
      <>
        <div
          className={className}
          style={{
            ...style,
            display: "flex",
            // backgroundColor: "rgb(250, 243, 243)",
            fontSize: "100px",
            borderRadius: "5px",
            color: "black",
            width: "70px",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1",
            backgroundColor: "#fff",
          }}
          onClick={onClick}
        >
          <MdArrowForwardIos
            style={{
              fontSize: "20px",
              fill: "#232528",
              // backgroundColor: "rgb(250, 243, 243)",
              width: "50px",
              height: "50px",
              padding: "15px",
              boxShadow: "-1px 1px 5px 1px #ccc",
              borderRadius: "50%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              // marginRight: "20px",
            }}
          />
        </div>
      </>
    );
  }

  function GrNextbtnResponsive(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          fontSize: "100px",
          borderRadius: "5px",
          color: "black",
          width: "10px",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1",
          backgroundColor: "#fff",
        }}
        onClick={onClick}
      >
        <MdArrowForwardIos
          style={{
            fontSize: "18px",
            fill: "#232528",
            backgroundColor: "white",
            width: "45px",
            height: "45px",
            padding: "13px",
            boxShadow: "-1px 1px 5px 1px #ccc",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: "334px",
            right: "36vw",
          }}
        />
      </div>
    );
  }

  const [activeData, setActiveData] = useState(0);

  const GrPreviousbtn = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          backgroundColor: "#fff",
          fontSize: "100px",
          borderRadius: "5px",
          color: "black",
          width: "80px",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1",
        }}
        onClick={onClick}
      >
        <MdArrowBackIosNew
          style={{
            color: "#232528",
            fontSize: "20px",
            backgroundColor: "#fff",
            width: "50px",
            height: "50px",
            padding: "15px",
            boxShadow: "-1px 1px 5px 1px #ccc",
            borderRadius: "50%",
            paddingRight: "20px",
          }}
        />
      </div>
    );
  };

  const GrPreviousbtnResponsive = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          fontSize: "100px",
          borderRadius: "5px",
          color: "black",
          width: "10px",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1",
          backgroundColor: "#fff",
        }}
        onClick={onClick}
      >
        <MdArrowBackIosNew
          style={{
            color: "#232528",
            fontSize: "20px",
            backgroundColor: "#fff",
            width: "50px",
            height: "50px",
            padding: "15px",
            boxShadow: "-1px 1px 5px 1px #ccc",
            borderRadius: "50%",
            paddingRight: "20px",
            position: "absolute",
            top: "330px",
            left: "45vw",
          }}
        />
      </div>
    );
  };

  const Expert = [
    {
      name: "Rachit Yadav",
      img: (
        <img
          src={Rachit}
          alt=""
          style={{height:"240px"}}
        />
      ),
      post: "Product Manager",
    },
    {
      name: "Mr. Akshay Shetty",
      img: (
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/10/Akshay-Shetty.jpeg"
          alt=""
        />
      ),
      post: "Senior Flutter Developer",
    },
    {
      name: "Umesh Prajapati",
      img: (
        <img
          src="https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-3.52.59-PM-2.jpeg"
          alt=""
        />
      ),
      post: "Accounts Head",
    },
    {
      name: "Sanchita Talekar",
      img: (
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-3.53.00-PM-2.jpeg"
          alt=""
        />
      ),
      post: "CRM Head",
    },
    {
      name: "Divya Lakkabathini",
      img: <img src={Divya} alt="" />,
      post: "Flutter Developer",
    },
    {
      name: "Shubham Misal",
      img: (
        <img src={Shubham} alt="" style={{ height: "240px", width: "245px" }} />
      ),
      post: "Node JS Developer",
    },
  ];

  const glimpse = [
    {
      img: <img src={GlimpseOne} alt="" />,
    },
    {
      img: <img src={GlimpseTwo} alt="" />,
    },
    {
      img: <img src={GlimpseThree} alt="" />,
    },
    {
      img: <img src={GlimpseFour} alt="" />,
    },
    {
      img: <img src={GlimpseFive} alt="" />,
    },
    {
      img: <img src={GlimpseSix} alt="" />,
    },
    {
      img: <img src={GlimpseSeven} alt="" />,
    },
    {
      img: <img src={GlimpseEight} alt="" />,
    },
    {
      img: <img src={GlimpseNine} alt="" />,
    },
    {
      img: <img src={GlimpseTen} alt="" />,
    },
    {
      img: <img src={GlimpseEleven} alt="" />,
    },
    {
      img: <img src={GlimpseTwelve} alt="" />,
    },
    {
      img: <img src={GlimpseThirteen} alt="" />,
    },
    {
      img: <img src={GlimpseFourteen} alt="" />,
    },
    {
      img: <img src={GlimpseFifthteen} alt="" />,
    },
  ];

  const SherTalking = [
    {
      img: (
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/11/highest-paid-games-player.jpg"
          alt=""
        />
      ),
      name: "Aditya Mishra",
      details:
        "It's easy to learn &amp; earn with Sherbazaar. I play games in Sherbazaar daily &amp; win exciting prizes &amp; hampers from the Sherbazaar team.",
    },
    {
      img: (
        <img
          src="https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-2.57.20-PM-2.jpeg"
          alt=""
        />
      ),
      name: "Rajesh Raut",
      details:
        "Playing games on Sher Bazaar is just as easy and interesting like any shopping app. Very economical.",
    },
    {
      img: (
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-2.57.20-PM-1-1.jpeg
    "
          alt=""
        />
      ),
      name: "Jayesh Parte",
      details:
        "Sher Bazaar is a game changer. Now, I learn & win more. Looking forward to more interesting games.",
    },
    {
      img: (
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/06/People-5.jpg"
          alt=""
        />
      ),
      name: "Arjun Khosla ",
      details:
        "With the help of Sher Bazaar I have ended up learning a lot more about stock markets. Very intuitive! ",
    },
    {
      img: (
        <img
          src="https://sherbazaar.co/wp-content/uploads/2022/06/People-2.jpg"
          alt=""
        />
      ),
      name: "Anirudh Sharma",
      details:
        "Great learning experience; learn, earn and repeat. Very simple to use, especially for newbies like me.",
    },
    {
      img: <img src={MCPeople} alt="" />,
      name: "MC People",
      details:
        "sher bazaar is very nice app without investment maine 1 lakh+ se bhi jyada earning kara hai aur mere sare dost sher bazaar use karke mahine ka 20 to 25 hajar Earning karte hai i love you sher bazaar",
    },
    {
      img: <img src={Abhinay} alt="" />,
      name: "Abhinay Rathore",
      details:
        "Sher Bazaar is a game changer. Now, I learn & win more.I play games in Sherbazaar daily & win exciting prizes.",
    },
    {
      img: <img src={Vikash} alt="" />,
      name: "Vikash meena",
      details:
        "Simple and fun App too play.I linked the feature  where I can Play international games too.Overall a great 👍 app .so fantastic app and simple earning app 🥰😍",
    },
    {
      img: <img src={VikashKumar} alt="" />,
      name: "Vikash Kumar",
      details:
        "This app is a total blast! The gameplay is addictive. I love how it constantly updates with new content and events to keep things fresh. Plus, Play and earn mode is so much fun. Highly recommended for gamers of all ages!",
    },
  ];

  const helpData = [
    {
      id: "1",
      tittle: "What is Sher Bazaar?",
      details:
        "Sher Bazaar is a fantasy game hosting platform. The Fantasy games hosted by it are played by using knowledge and skill.",
    },
    {
      id: "2",
      tittle: "How does Sher Bazaar works?",
      details:
        "Sher Bazaar is a mobile gaming app based on real capital markets, where you test your stock picking knowledge and skills against other players for cash prizes. So learn and earn at the same time! Our daily contests/games are open to all players, subject to eligibility conditions as per laws of the Republic of India.   Simply register yourself with the app using your mobile number, then choose from the available game/contest you wish to play and select the requisite number of stocks as per the requirements of the contest/game and hit continue. Pay the minimal entry fees after completing KYC and boom, you are in the game. If your selection makes the highest points when the stock market closes, you’ll be rewarded in form of cash prizes as per the leaderboard position.",
    },
    {
      id: "3",
      tittle: "Is Sher Bazaar available on iOS devices?",
      details:
        "Yes, Sher Bazaar mobile application is available for both Android and iOS operated mobile devices.",
    },
    {
      id: "4",
      tittle: "How to register on Sher Bazaar?",
      details:
        "Open the app > Insert your mobile number and email ID > Generate One Time Password > Insert the One Time Password as received on mobile number to verify yourself.",
    },
    {
      id: "5",
      tittle:
        "Is there any eligibility criteria? Is there any approval required?",
      details:
        "No. As long as the user is above 18 years of age and eligible to enter into valid contract and not residing Andhra Pradesh, Assam, Orissa, Sikkim, Telangana, Nagaland, Tamil Nadu, the user can take part in the games/contests. ",
    },
    {
      id: "6",
      tittle: "Not receiving the OTP, what to do?",
      details:
        "Usually, OTP will be received in 30 seconds of providing the mobile number. If not received, click on ‘Resend OTP’.Please check that mobile number entered is correct and you have a stable network connection. Usually, OTP will be received in 30 seconds of providing the mobile number. If not received, click on ‘Resend OTP’.Please check that mobile number entered is correct and you have a stable network connection. ",
    },
    {
      id: "7",
      tittle: "How to proceed if you have a referral code?",
      details:
        "If you have a referral code, then enter the same you while you register yourself when you get asked: “Do you have a referral code?” or “Enter referral code”.",
    },
    {
      id: "8",
      tittle: "Forgot my mobile number, how do I login?",
      details:
        "Email us on our customer care ID ( care@sherbazaar.com ) and we’ll be happy to assist you.",
    },
    {
      id: "9",
      tittle: "Can I change my email id post registration?",
      details:
        "No, you cannot change your email address once you have created your account. Your email address is like a special code that identifies your account, and changing it could cause problems.",
    },
    {
      id: "10",
      tittle: "Can I change my Game Name?",
      details:
        "Click on the ‘Sherbazaar Logo ’ at top left of the home screen > Click on the edit symbol aside your Game Name > Type your new Game Name > Click on Update. Your new avatar stands updated.",
    },
    {
      id: "11",
      tittle: "My account has been suspended. How can I access it again?",
      details:
        "Users can be suspended from our platform subject to our Fair Play policy. Kindly write to our customer care id ( care@sherbazaar.com ) and we’ll surely assist you. ",
    },
    {
      id: "12",
      tittle:
        "Do I have to accept the Terms and Conditions, Privacy Policy, Fairplay Policy and other policies and terms?",
      details:
        "Yes, you must agree to all terms of service, privacy policy, fairplay policy and other policies as a first time user.",
    },
    {
      id: "13",
      tittle: "Is the App secured by Pin or Password or Biometric?  ",
      details:
        "The Mobile App accepts pin, password or biometric (fingerprint and iris) for authentication for opening the app in the mobile is available. It depends on what type of authentication is set by the User in the Mobile device.",
    },
    {
      id: "14",
      tittle:
        "How can I take a selfie or upload a photo from phone on the Sher Bazaar App?",
      details:
        "a.	Taking a selfie: Subsequent to the user having entered the OTP for registration, the User has an option to take the selfie and set it as the identification icon.  b.	Uploading any image or photo: Click on the Camera Icon > Click on “Choose from Device> Select Photo/Image and click Upload Sign  c.	Uploading or Changing Photo: Go To Menu > Click on Pencil Icon on the Username > Click on Upload. You can either Open a Camera, Click a Selfie and upload it or you can choose your favourite picture from Gallery.",
    },
    {
      id: "15",
      tittle: "When do I get my Bonus for signing up? ",
      details:
        "As soon as you complete your sign up with our referral link, you receive your bonus and the same would reflect in your wallet.",
    },
    {
      id: "52",
      tittle: "How can I get additional bonus? ",
      details:
        "Users who complete their KYC on Sherbazaar are eligible for an extra bonus and can also earn more bonuses by referring their friends and family to the platform.",
    },
    {
      id: "16",
      tittle: "What is the format of games in Sher Bazaar?",
      details:
        "At Sherbazaar you will get variety of games based on live market stimulation. Sherbazaar has games based on different Capital Market Assets (Indian Stocks, Commodities, Currencies, Crypto Currencies & International stocks). Games based on Crypto currencies can be played 24hours. Other games are based on respective exchange timings. ",
    },
    {
      id: "17",
      tittle: "How to play game at Sher Bazaar?",
      details:
        "On Sherbazaar, you select the Contest which you want to play like game of 03 or 05 or 07 in Shares, Commodities, Crypto Currencies, Currencies, International Stocks. Select the assets based on contest & create the army. Amongst the selected Scrips, elect any one asset as a captain. The captain’s performance will fetch you double the points. You can analyse your performance in my game sections throughout the day & also compare your assets selection with other assets.  Result of your army performance will be made instantly after completion of game.",
    },
    {
      id: "18",
      tittle: "Can I actually win money on Sher Bazaar?",
      details:
        "Absolutely, you can definitely win big prizes on Sher Bazaar. There are different types of contest where the prize money varies from Rs. 800- Rs. 100000 this changes from time to time. We host different kinds of cash contests, each with its own entry fee and prize money. After a game ends, if you’re in the winning ranks designated for a contest, then your respective winnings are transferred to your account. Use them to play more contests and enhance your skills. All the Best!",
    },
    {
      id: "19",
      tittle: "How many different games are present in Sher Bazaar?",
      details:
        "Sher Bazaar hosts fantasy games involving different kinds of capital assets like assets, Crypto currencies, Currency derivatives, International stocks and Commodities.  There are several different games available in the app like game of 03, game of 05 and game of 07 In game of 03 a User may select any 3 assets / Currency / Commodities / Crypto currency / International stocks out of the available respective list of capital assets.   In game of 05 User may select any 5 Stocks / Commodities / Crypto currency / International stocks out of the available list of the respective capital asset and similarly for game of 07.   As per the requirements of the respective game, the Users will be required to make their army and enter into the game.  In monthly contest you can win rewards of more than 1crore and even exciting prizes like Cars, Bikes, Iphones etc. ",
    },
    {
      id: "20",
      tittle: "Can I play multiple type of games at once?",
      details:
        "Yes! One user can play as many numbers of games or type of games as he desires, meaning thereby participant can play the same game also multiple times irrespective of its frequency. However, participation can be restricted by the company at any point of time in any game.",
    },
    {
      id: "21",
      tittle: "Can I delete a previously created army?",
      details:
        "Yes! Once an army is created and a user has picked up his stocks, they can modify the army at any time before the starting of game.",
    },
    {
      id: "22",
      tittle: "Can I create an army in middle of the contest day?",
      details:
        "You cannot create or change your army in the middle of contests. Any change in the game has to be done before the start of the game.",
    },
    {
      id: "23",
      tittle: "When is a winner/result declared of the contest?",
      details:
        "Results are declared post the closing time of the stock market. Thereby, the result will be declared immediately after the contest is over. ",
    },
    {
      id: "24",
      tittle: "Can I change my picks before the contest start time?",
      details:
        "Yes, we allow changing the team after a team has been created. You can make as many changes to your Armies as you like until the deadline of the start of the Contest.",
    },
    {
      id: "25",
      tittle: "How do you decide the winner of a contest?",
      details:
        "The Army (ies) which gets the highest points at the end of the Contest will be announced as the winner of that contest. Winners here means the teams which are entitled for a prize based on their respective ranks. ",
    },
    {
      id: "26",
      tittle: "When will I get my prize money in my wallet?",
      details:
        "After the contest is completed and the results are be verified, the results will be declared and the winning amount will be credited to your wallet in half an hour of the declaration of the results. ",
    },
    {
      id: "27",
      tittle:
        "Do I have to pay taxes when I withdraw money to my bank account?",
      details:
        "Yes, we deduct TDS on winnings as per Government of India Policies. See our TDS policy for details.   ",
    },
    {
      id: "28",
      tittle:
        "What if the contest doesn’t fill up with the required number of participants?",
      details:
        "In such a case the contest will be cancelled and the service fee as well as the prize pool contribution will be refunded to your account. ",
    },
    {
      id: "29",
      tittle: "Can I join future matches and contests in advance?",
      details:
        "No we only allow Users to select their teams for the Contests that are open on the Mobile App Fantasy at any given point of time.",
    },
    {
      id: "30",
      tittle: "How there is movement in asset prices and ranks are determined?",
      details:
        "The points and consequently the ranks of the players will be computed and decided on the basis of the individual as well as overall intraday performance of the Capital Asset and the teams during the trading session at the ticker. The points are derived by multiplying % change in the price of the Capital Asset in intraday with 100. (One point is awarded for every gain of 0.0% in the price of the Capital Asset and vice versa). For every movement in the Capital Asset price, which is in line with your selection i.e. Up and Down, all the players, get +1 point, and if the movement is opposite to your selection then you will get -1 point. Your points and thereto your ranking keep changing as per the movement of the asset. When market is live, you can follow your game leader board to see how you’re performing against your competition. The results will be declared in 30 minutes post the closure of the market session of that particular Capital Asset and win real money from your skills.",
    },
    {
      id: "31",
      tittle: "How do I deposit money?",
      details:
        "You can deposit money on Sher Bazaar Mobile Application using any of the Modes present on the checkout page powered by Cashfree after completing your KYC. The modes include UPI, Prepaid payment Instruments like PhonePe, Paytm etc., Credit Cards , Debit Cards, Net-banking of over 50+ banks.",
    },
    {
      id: "32",
      tittle: "Is it safe to add money to Sher Bazaar?",
      details:
        "Adding money to your account at Sher Bazaar app is both simple and 100% safe. We have many different payment options and work hard to ensure that your personal details are safe with us. You can easily withdraw the money on completion of KYC by one click, and your winning amount will be transferred to your bank account.",
    },
    {
      id: "33",
      tittle:
        "Money from the bank account is debited and not credited to my Sher Bazaar account?",
      details:
        "There may have been a technical issue at your bank end due to which this happened. If we receive the money we will credit it to your Sher Wallet. If we do not receive the money the same will be refunded to your bank account by your bank in 7-10 working days. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "34",
      tittle:
        "My Transaction says its been pending and money from my bank account is debited. What to do?",
      details:
        "The user can make another transaction. Any pending transaction where amount is debited from your account but not credited in your Sher Wallet, is credited back by your bank in 7-10 business days. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "35",
      tittle: "My transaction is showing as pending. What to do?   ",
      details:
        "It may take upto 2 business days for a UPI transaction and 3-4 business days for bank transfer to be reflected in your account. Do not worry, if the transaction fails at any point the money will be deposited back to your Sher wallet. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "36",
      tittle: "Money has been withdrawn but not received in the Bank Account.",
      details:
        "If the withdrawal is marked success, we will credit the same to your account. Due to technical errors at your bank end, there might be a delay with reflecting the amount. Please wait for 3-4 business days for the same, or raise the issue to the bank, with the details of the transaction that you can find in transaction history. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "37",
      tittle: "Withdrawal has been pending for 7 days. What to do?",
      details:
        "If such situation happens, we will credit your amount back to the winnings wallet if it is pending at our end. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "38",
      tittle: "How do I change my Bank account/ UPI account?",
      details:
        "As of now for security reasons, you cannot change the Bank/ UPI account or PPI information linked to your Sher Bazaar account. Please feel free to write us on care@sherbazaar.com in case of any deficiency.",
    },
    {
      id: "39",
      tittle: "Can a user withdraw all his monies winnings at once?",
      details:
        "Yes. We do not have any limit on withdrawal, you can withdraw any amount above Rs.100/-. The monies available to the credit of the Sher Wallet apart from the bonus are available to able to be withdraw.",
    },
    {
      id: "40",
      tittle: "Can a user withdraw all his monies from Sher Wallet?",
      details: " No. Sher wallet balance can only be used to play games.",
    },
    {
      id: "41",
      tittle:
        "	Can a user use PAN Card & Bank Details of their Friends & Family?",
      details:
        "No, it is not permissible to use PAN card and bank details of friends and family members for creating an account or making transactions. PAN card and bank details are unique to each individual and should only be used by the authorized owner. Using someone else's PAN card or bank details without their consent is considered fraud and can result in legal consequences.",
    },
    {
      id: "42",
      tittle: "Can the same bank account be used by multiple users?",
      details: "No.",
    },
    {
      id: "43",
      tittle: "	Can the same Pan Card be used by multiple users?",
      details: "No.",
    },
    {
      id: "44",
      tittle:
        "	If name on Pan Card & Bank statement are different, then they can get approved?",
      details: "No. Name of PAN Card & Bank statement must be same. ",
    },
    {
      id: "45",
      tittle: "How can I shift from Old app to New App?",
      details:
        "First uninstall your old app then go to play store & search for SherBazaar & install directly from Play Store or you can also directly download our latet app from our website ( https://sherbazaar.com/ ) Happy Playing!!!",
    },
  ];

  const ExpertArmySlider = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "Slider",
    nextArrow: <GrNextbtn />,
    prevArrow: <GrPreviousbtn />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          nextArrow: <GrNextbtnResponsive />,
          prevArrow: <GrPreviousbtnResponsive />,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          nextArrow: <GrNextbtnResponsive />,
          prevArrow: <GrPreviousbtnResponsive />,
        },
      },
    ],
  };

  const sliderData = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: BizClass.Slider,
    // centerMode: true,
    autoplay: true,
    dotsClass: "slick-dots custom-indicator",
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          // centerMode: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          // centerMode:true,
        },
      },
    ],
  };

  const QuickApp = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 5,
    autoplay: true,
    // centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // centerMode: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 3,
          activeData: true,
        },
      },
    ],
  };

  const [imageIndex, setImageIndex] = useState(0);
  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className={BizClass.Home_Page}>
        <div className={BizClass.section}>
          <div className={BizClass.Top_Section}>
            <div className={BizClass.SocialLogo}>
              <FaInstagram />
              <FaFacebookF />
            </div>
            <div className={BizClass.Container}>
              <div className={BizClass.left_Section}>
                <h4
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  Capital Markets Simplified<br></br> for Maximum Fun!
                </h4>
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  Discover the Secrets of Learning, Earning, and Navigating <br></br>the World of Stocks,
                  Commodities, Cryptos, and Currencies with a
                  <br></br>joining bonus of <strong style={{ color: "#ff5534" }}>500K</strong> Shercoins.
                </span>
                <div
                  className={BizClass.TwoItem}
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <a
                    href="https://sherbazaarcdn.com/sherbazaar.apk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Android />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/sherbazaar/id1613349921"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://sherbazaar.co/wp-content/uploads/2022/06/appstore-2.svg"
                      alt=""
                    />
                  </a>
                </div>
                <div className={BizClass.rating} data-aos="fade-left">
                  <div>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <span>
                      (based on <p> 25,000 </p>reviews)
                    </span>
                  </div>
                </div>
              </div>
              <div className={BizClass.Right_Section}>
                <div
                  className={BizClass.Img}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <img src={ImageOne} alt="" className="" />
                </div>
              </div>
            </div>
          </div>
          <div className={BizClass.AniamtionWaveDiv}>
            <div className={BizClass.Wave} />
          </div>
        </div>
        <div className={BizClass.SectionTwo}>
          <div className={BizClass.Conatiner_One}>
            <img src={GlimpseSix} alt="" />
            <div className={BizClass.Content}>
              <div></div>
              <h4> Get the app</h4>
              <h2>for exclusive features</h2>
              <p>100% Safe and Secure</p>
              <div className={BizClass.App_Data}>
                <a
                  href="https://sherbazaarcdn.com/sherbazaar.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="	https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/btn1.png"
                    alt=""
                  />
                </a>
                <a
                  href="https://apps.apple.com/in/app/sherbazaar/id1613349921"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="	https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/btn2.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={BizClass.Reward_Offers} style>
          <h3>Rewards & Offers</h3>
          <p>Exciting rewards & offers running constantly to choose from </p>
          <div className={BizClass.Container}>
            {" "}
            <RewardsOffers />
          </div>
        </div> */}
        {/* <div className={BizClass.Section_Three}>
          <h3>Trending Games</h3>
          <p>Predict the Stock Movement and Win</p>
          <div className={BizClass.Container}>
            <Responsive />
          </div>
        </div> */}
        <HowPlay />
        <div className={BizClass.Section_Five}>
          <Video />
        </div>
        <div className={BizClass.Section_Six}>
          <div className={BizClass.ImgSection}>
            <img src={GlimpseEleven} alt="" className={BizClass.Six_Img} />
            <img
              className={BizClass.ColorFullOne}
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-2.png"
              alt=""
            />
            <img
              className={BizClass.ColorFull}
              src="https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-3.png"
              alt=""
            />
          </div>

          <div className={BizClass.Content}>
            <div>
              <ul>
                <div>
                  {" "}
                  <li>
                    <BiSquare />
                  </li>
                  <li>
                    <BiSquare />
                  </li>
                </div>
                <div>
                  {" "}
                  <li>
                    <BiSquare />
                  </li>
                  <li>
                    <BiSquare />
                  </li>
                </div>
              </ul>

              <span>Insights</span>
            </div>
            <h4>We have some awesome</h4>
            <h2>stats for users</h2>
            <p>Because we know you love numbers</p>
            <div className={BizClass.Insights}>
              <span>
                40+<p>Games</p>{" "}
              </span>
              <span>
                60K+<p>Active User</p>{" "}
              </span>
              <span>
                1.5cr +<p>Rewards</p>{" "}
              </span>
              <span>
                3 Lakhs+<p>Downlods</p>{" "}
              </span>
            </div>
          </div>
        </div>
        <div className={BizClass.Section_Seven}>
          <div className={BizClass.Section}>
            <div className={BizClass.Content_Div}>
              <div className={BizClass.Content}>
                <div>
                  <div className={BizClass.card}>
                    <h5>Hands on Experience</h5>
                    <p>
                      Participate in games that simulate real market scenarios, allowing you to apply what you've learned in a risk-free environment.
                    </p>
                  </div>
                  <div className={BizClass.Icon}>
                    <div>
                      <FaMobileAlt />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={BizClass.card}>
                    <h5>Accessible to Everyone</h5>
                    <p>
                      Designed for the common masses, our app provides accessible and user-friendly tools to make learning approachable for all.
                    </p>
                  </div>
                  <div className={BizClass.Icon}>
                    <div>
                      <BsUniversalAccessCircle />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={BizClass.card}>
                    <h5>Stay Informed</h5>
                    <p>
                      Keep up with the latest trends and developments in the capital market, ensuring you remain informed and confident in your financial choices.
                    </p>
                  </div>
                  <div className={BizClass.Icon}>
                    <div>
                      <MdNotificationsActive />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={BizClass.Img}>
              <h2>
                Reason To <br /> Download Our <br /> App
              </h2>
              <img src={GlimpseNine} alt="" />
            </div>
          </div>
        </div>
        <div className={BizClass.Section_Eight}>
          <h3>Expert Army</h3>
          <div className={BizClass.Slider_Conatiner}>
            <Slider {...ExpertArmySlider} className={BizClass.Slider}>
              {Expert.map((data, index) => {
                return (
                  <>
                    <div className={BizClass.Card}>
                      <div key={index}>
                        {data.img}
                        <h5>{data.name}</h5>
                        <span>{data.post}</span>
                      </div>
                      <div className={BizClass.SocialLogo}>
                        <ul>
                          <li>
                            <FaFacebookF />
                          </li>
                          <li>
                            <FaBehance />
                          </li>
                          <li>
                            <FaApple />
                          </li>
                          <li>
                            <FaTwitter />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </div>
        <div className={BizClass.Section_Nine}>
          <div className={BizClass.Header}>
            <div className={BizClass.Content}>
              <div>
                <ul>
                  <div>
                    {" "}
                    <li>
                      <BiSquare />
                    </li>
                    <li>
                      <BiSquare />
                    </li>
                  </div>
                  <div>
                    {" "}
                    <li>
                      <BiSquare />
                    </li>
                    <li>
                      <BiSquare />
                    </li>
                  </div>
                </ul>

                <span>Sher Screenshots</span>
              </div>
              <h4>A quick glimpse of the app</h4>
              <p>Let's take you through what's inside</p>
            </div>
          </div>
          <div className={BizClass.Slider_div}></div>
          {/* <Glimpse /> */}
          <Slider
            className={BizClass.Slider}
            {...QuickApp}
            beforeChange={(current, next) => setImageIndex(next)}
            dotsClass="slick-dots custom-indicator"
            customPaging={(i) => {
              return (
                <div className={BizClass.Active}>
                  <img
                    src={glimpse[i]}
                    alt=""
                    style={{
                      width: "120px",
                      height: "8px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            }}
          >
            {glimpse.map((data, index) => {
              return (
                <div
                  className={
                    index === imageIndex ? BizClass.activeSlide : BizClass.slide
                  }
                >
                  {data.img}
                </div>
              );
            })}
          </Slider>
        </div>
        <div className={BizClass.Section_Ten}>
          <div className={BizClass.Header}>
            <div className={BizClass.Content}>
              <div>
                <ul>
                  <div>
                    {" "}
                    <li>
                      <BiSquare />
                    </li>
                    <li>
                      <BiSquare />
                    </li>
                  </div>
                  <div>
                    {" "}
                    <li>
                      <BiSquare />
                    </li>
                    <li>
                      <BiSquare />
                    </li>
                  </div>
                </ul>

                <span>Testimonials</span>
              </div>
              <h4>
                Let our SHER <br />
                do the talking!
              </h4>
            </div>
          </div>
          <div className={BizClass.Container}>
            <Slider
              dots={true}
              {...sliderData}
              className={BizClass.Slider}
              customPaging={(i) => {
                return (
                  <div className={BizClass.Active}>
                    <img
                      src={SherTalking[i]}
                      alt=""
                      style={{
                        width: "120px",
                        height: "8px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                );
              }}
            >
              {SherTalking.map((data, index) => {
                return (
                  <>
                    <div className={BizClass.Card_Div} key={index}>
                      <FaQuoteRight />
                      <div className={BizClass.Card}>
                        <img
                          className={BizClass.Zigzag}
                          src="	https://sherbazaar.co/wp-content/themes/appilo/img/app-showcase/team/t-shape2.png"
                          alt=""
                        />
                        <div className={BizClass.Img}>{data.img}</div>
                        <div className={BizClass.Content_Div}>
                          <h3>{data.name}</h3>
                          <p>{data.details} </p>
                          <div>
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
          <div className={BizClass.Section}>
            <div>
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/11/highest-paid-games-player.jpg"
                alt=""
              />
              <img
                src="https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-2.57.20-PM-2.jpeg"
                alt=""
              />
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-2.57.20-PM-1-1.jpeg
"
                alt=""
              />
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/06/People-5.jpg"
                alt=""
              />
              <img
                src="https://sherbazaar.co/wp-content/uploads/2022/06/People-2.jpg"
                alt=""
              />
            </div>
          </div>
          <div className={BizClass.Section_Img_Two}>
            <div>
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/11/highest-paid-games-player.jpg"
                alt=""
              />
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/07/WhatsApp-Image-2022-07-02-at-2.57.20-PM-1-1.jpeg
"
                alt=""
              />
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/06/People-5.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={BizClass.Section_Eleven}>
          <div className={BizClass.Content}>
            <div>
              <ul>
                <div>
                  {" "}
                  <li>
                    <BiSquare />
                  </li>
                  <li>
                    <BiSquare />
                  </li>
                </div>
                <div>
                  {" "}
                  <li>
                    <BiSquare />
                  </li>
                  <li>
                    <BiSquare />
                  </li>
                </div>
              </ul>

              <span>Frequently Asked Questions</span>
            </div>
            <h4>Let us help you</h4>
            <p>
              in some of your <br /> FAQ's too
            </p>
          </div>
          <div className={BizClass.Div_Section}>
            {helpData.map((data, index) => {
              return (
                <>
                  <div
                    className={activeData === data.id ? BizClass.Active : ""}
                    onClick={() => setActiveData(data.id)}
                  >
                    <div className={BizClass.Tittle}>
                      <span>
                        <TiPlus />
                      </span>
                      <h4>{data.tittle}</h4>
                    </div>
                    {activeData === data.id ? <p>{data.details}</p> : ""}
                  </div>
                </>
              );
            })}
          </div>
          <div className={BizClass.Circle_Img}>
            <img
              src="https://sherbazaar.co/wp-content/uploads/2022/06/Elements-1.png"
              alt=""
            />
            <img
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-4-1.png"
              alt=""
            />
            <span>
              <img
                src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-5-1.png"
                alt=""
              />
            </span>
          </div>
          <div className={BizClass.layer_Img}>
            <img
              src="https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/fq-shape6.png"
              alt=""
            />
          </div>
          <div className={BizClass.Triangle}>
            <img
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-3.png"
              alt=""
            />
            <img
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-3.png"
              alt=""
            />
          </div>
        </div>
        <div className={BizClass.Section_Twelve}>
          <DownloadApp />
        </div>
        <div className={BizClass.Section_thirteen}>
          <div className={BizClass.Container}>
            <span>
              Subscribe to our <p>newsletter!</p>{" "}
            </span>
            <div>
              <input
                type="text"
                placeholder="Enter Your Email Address"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <button type="button" onClick={(e) => _subscribeNow(e)}>
                {isLoading ? (
                  <div className={BizClass.DynBiz_btn__spinner}>
                    <div className={BizClass.DynBiz_btn__bounce1}></div>
                    <div className={BizClass.DynBiz_btn__bounce2}></div>
                    <div className={BizClass.DynBiz_btn__bounce2}></div>
                  </div>
                ) : (
                  "Subscribe Now"
                )}
              </button>
            </div>
            <p>* Your mail address will be fully secure . We don’t share!</p>
          </div>
          <span>
            <img
              src="https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/n-shape2.png"
              alt=""
            />
            <img
              src="https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/n-shape2.png"
              alt=""
            />
            <img
              src="	https://themexriver.com/appilo-theme/app-showcase/wp-content/uploads/sites/4/2021/07/n-shape3.png"
              alt=""
            />
          </span>
        </div>
        <div>
          <div className={BizClass.qrcodeIMages}>
            <img src="./icici_qrcode.jpeg" style={{ width: "100%" }} />
            <img src="./gapy_qrcode.jpeg" style={{ width: "100%" }} />
            <img src="./yes_qrcode.jpeg" style={{ width: "100%" }} />
          </div>
        </div>
        <Footer />
        <div className={BizClass.Sticky_Footer}>
          <span>To Redeem Sher Coins Download Sherbazaar Pro App</span>
          <div>
            <a
              download
              href="https://sherbazaarcdn.com/sherbazaar.apk"
              className={BizClass.AppLink}
            >
              Download Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
