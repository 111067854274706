import React from "react";
import BizClass from "../Biz_Home.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={BizClass.Section_Footer}>
      <div className={BizClass.BackGround_Img}>
        <img
          src="	https://sherbazaar.co/wp-content/uploads/2022/06/eimap-1.png"
          alt=""
        />
      </div>
      <div className={BizClass.Footer}>
        <div className={BizClass.Links}>
          <span className={BizClass.FooterLink}>
            © 2023 Copyright – Sher Bazaar
          </span>
          <Link
            className={BizClass.FooterLink}
            to="/refund-policy"
            target="_blank"
          >
            Cash & Withdrawal Policy
          </Link>
          <Link
            className={BizClass.FooterLink}
            to="/terms-and-conditions"
            target="_blank"
          >
            Standard Terms & Conditions.
          </Link>
          <Link
            className={BizClass.FooterLink}
            to="/fair-play-policy"
            target="_blank"
          >
            Fair Play Policy
          </Link>
          <Link
            className={BizClass.FooterLink}
            to="/tds-policy"
            target="_blank"
          >
            TDS Policy
          </Link>
          <Link
            className={BizClass.FooterLink}
            to="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
        <p>
          Disclaimer: The games involve financial risk and may result into
          dependence. Please read all the terms and conditions carefully prior
          using the Sher Bazaar App. Indulge at your own personal risk. This app
          is not regulated by SEBI. Hence, benefit of Investor protection under
          SEBI / Exchange(s) Jurisdiction, Exchange dispute resolution mechanism
          and Investor greviance redressal mechanism administered by Exchange(s)
          will not be available with the Users.
        </p>
      </div>
    </div>
  );
};

export default Footer;
