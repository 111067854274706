import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
} from "react-router-dom";
import Header from "../components/Header/TopHeader/Header";
import Home from "../components/Home/Home";
const About_Us = React.lazy(() => import("../components/AboutUS/About_Us"));
const HowToPlay = React.lazy(() => import("../components/HowToPlay/HowToPlay"));
const ContactUs = React.lazy(() => import("../components/ContactUS/ContactUs"));
const Blog = React.lazy(() => import("../components/Blog/Blog"));
const PrivacyPolicy = React.lazy(() =>
  import("../components/Home/Footer/PrivacyFolder/PrivacyPolicy")
);
const FairPlay = React.lazy(() =>
  import("../components/Home/Footer/FairPlay/FairPlay")
);
const TermsConditions = React.lazy(() =>
  import("../components/Home/Footer/TermsConditions/TermsConditions")
);
const TDS = React.lazy(() =>
  import("../components/Home/Footer/TDSPolicy/TDSPolicy")
);
const RefundCancellation = React.lazy(() =>
  import("../components/Home/Footer/RefundCancellation/RefundCancellation")
);

const PrivacyPolicyLite = React.lazy(() =>
  import("../components/Lite/PrivacyPolicy/PrivacyPolicyLite")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route
        index
        element={
          <React.Suspense fallback={<>...</>}>
            <Home />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/about-us"
        element={
          <React.Suspense fallback={<>...</>}>
            <About_Us />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/how-to-play"
        element={
          <React.Suspense fallback={<>...</>}>
            <HowToPlay />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/blog/:slug"
        element={
          <React.Suspense fallback={<>...</>}>
            <Blog />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/contact-us"
        element={
          <React.Suspense fallback={<>...</>}>
            <ContactUs />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
          <React.Suspense fallback={<>...</>}>
            <PrivacyPolicy />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/tds-policy"
        element={
          <React.Suspense fallback={<>...</>}>
            <TDS />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/fair-play-policy"
        element={
          <React.Suspense fallback={<>...</>}>
            <FairPlay />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/terms-and-conditions"
        element={
          <React.Suspense fallback={<>...</>}>
            <TermsConditions />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/refund-policy"
        element={
          <React.Suspense fallback={<>...</>}>
            <RefundCancellation />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/lite/privacy-policy"
        element={
          <React.Suspense fallback={<>...</>}>
            <PrivacyPolicyLite />
          </React.Suspense>
        }
      />
    </Route>
  )
);

export default router;

function RootLayout() {
  return (
    <React.Fragment>
      <Header />
      <Outlet />
    </React.Fragment>
  );
}
