import React from "react";
import BizClass from "../Biz_Home.module.scss";
import { BiUserCheck } from "react-icons/bi";

const HowPlay = () => {
  const HowPlay = [
    {
      number: "1",
      icon: <BiUserCheck />,
      head: "Select Stocks",
      Details:
        "  Select the required number of stocks as per the requirements of the game. For eg. In game of 03, you will be required to select 3 different stocks, each from one sector, from their respective sectors. Likewise as per the requirements of the game you will have to select the requisite number of stock and this will form your Army.",
    },
    {
      number: "2",
      icon: <BiUserCheck />,
      head: "Select Captain",
      Details:
        " Amongst the above selected stocks, elect any one as 'captain' who, as per your assessment, will outperform the other stocks. The captain's performance will fetch you double points.",
    },
    {
      number: "3",
      icon: <BiUserCheck />,
      head: "Create Multiple Army",
      Details:
        " You can create as many Army as you want and play as many games as you want.",
    },
    {
      number: "4",
      icon: <BiUserCheck />,
      head: "Analyse Your Performance",
      Details:
        " You can analyse your performance in my games section of the app. The performance can be analysed throughout the day, after declaration of final results. You can also compare performance of individual stocks in comparison to other stocks.",
    },

    {
      number: "5",
      icon: <BiUserCheck />,
      head: "Results",
      Details:
        " Results of your Army performance will be made available within 30 minutes of closing of real market. For each of your selected stocks individually, One point will be awarded for every 0.01% change in price of your stock i.e. for every 0.01% change in stock price you will get, +1 if stock price moves as per your assessment and -1 points if stock price moves against your assessment.",
    },
  ];
  return (
    <div className={BizClass.Section_Four}>
      <div className={BizClass.Conatiner_Two}>
        <div className={BizClass.Content}>
          <div className={BizClass.Content_One}>
            <h3>How To Play</h3>
            <p>
              Easy to Learn & Play <br /> With Stock based Games!
            </p>
            <div className={BizClass.Cards_Container}>
              {HowPlay.map((data, index) => {
                return (
                  <>
                    <div className={BizClass.Card_Div} key={index}>
                      <h1>{data.number}</h1>
                      <div className={BizClass.Card}>
                        <div className={BizClass.Icon}>
                          <div>{data.icon}</div>
                        </div>
                        <div className={BizClass.InfoDiv}>
                          <h3>{data.head}</h3>
                          {/* <p>{data.Details}</p> */}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className={BizClass.Content_Two}>
            <img
              src="https://sherbazaar-vega-assetroot.s3.ap-south-1.amazonaws.com/uploads/public/cdn/mobileapp.gif"
              alt=""
            />
            {/* <video width="auto" height="350" loop controls>
              <source
                src="https://sherbazaar-vega-assetroot.s3.ap-south-1.amazonaws.com/uploads/public/cdn/HowPlayVideo.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video> */}
          </div>
          <div className={BizClass.ColorFull_Img}>
            <img
              className={BizClass.ColorFull}
              src="	https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-2.png"
              alt=""
            />
            <img
              src="https://sherbazaar.co/wp-content/uploads/2022/06/Elements-2-3.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowPlay;
